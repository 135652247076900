import i18n from "@/i18n";
import { markRaw, toRaw } from "vue";
import {
  Plus,
  BottomLeft,
  TopRight,
  Search,
  Download,
} from "@element-plus/icons-vue";
import api from "@/api";
import moment from "moment";
import { getUploadUrl } from "../../utils/tools";
import { ru } from "element-plus/es/locale";
const { t } = i18n.global;
export default {
  tabsLabel: {
    tabA: t("map.changguibaojing"),
    tabB: t("map.xiangqingtongji"),
  },
  tableCols: [
    { name: t("report.cph"), filed: "cph", width: "200" },
    { name: t("report.sbh"), filed: "sbh", width: "200" },
    { name: t("report.ssgs"), filed: "ssgs" },
    {
      name: t("report.csc"),
      filed: "cssl",
      render: (row) => {
        if (row.cssl > 0) {
          return (
            "<span style='color:#f00;cursor: pointer;'>" + row.cssl + "</span>"
          );
        } else {
          return row.cssl;
        }
      },
      click: (row, callback) => {
        // console.log("wxw", row.cpyc);
        if (parseInt(row.cssl) > 0)
          callback({ type: "detail_type", row: row, kind: 1 });
      },
    },
    {
      name: t("report.sgc"),
      filed: "sgsl",
      render: (row) => {
        if (row.sgsl > 0) {
          return (
            "<span style='color:#f00;cursor: pointer;'>" + row.sgsl + "</span>"
          );
        } else {
          return row.sgsl;
        }
      },
      click: (row, callback) => {
        // console.log("wxw", row.cpyc);
        if (parseInt(row.sgsl) > 0)
          callback({ type: "detail_type", row: row, kind: 2 });
      },
    },
    {
      name: t("report.pljsbj"),
      filed: "pljsbj",
      width: "100",
      render: (row) => {
        if (row.pljsbj > 0) {
          return (
            "<span style='color:#f00;cursor: pointer;'>" +
            row.pljsbj +
            "</span>"
          );
        } else {
          return row.pljsbj;
        }
      },
      click: (row, callback) => {
        // console.log("wxw", row.cpyc);
        if (parseInt(row.pljsbj) > 0)
          callback({ type: "detail_type", row: row, kind: 3 });
      },
    },
    {
      name: t("report.cstcbj"),
      filed: "cstcbj",

      render: (row) => {
        if (row.cstcbj > 0) {
          return (
            "<span style='color:#f00;cursor: pointer;'>" +
            row.cstcbj +
            "</span>"
          );
        } else {
          return row.cstcbj;
        }
      },
      click: (row, callback) => {
        // console.log("wxw", row.cpyc);
        if (parseInt(row.cstcbj) > 0)
          callback({ type: "detail_type", row: row, kind: 4 });
      },
    },
    {
      name: t("report.sosc"),
      filed: "sossl",
      render: (row) => {
        if (row.sossl > 0) {
          return (
            "<span style='color:#f00;cursor: pointer;'>" + row.sossl + "</span>"
          );
        } else {
          return row.sossl;
        }
      },
      click: (row, callback) => {
        // console.log("wxw", row.cpyc);
        if (parseInt(row.sossl) > 0)
          callback({ type: "detail_type", row: row, kind: 5 });
      },
    },
    {
      name: t("report.cjc"),
      filed: "cjsl",
      render: (row) => {
        if (row.cjsl > 0) {
          return (
            "<span style='color:#f00;cursor: pointer;'>" + row.cjsl + "</span>"
          );
        } else {
          return row.cjsl;
        }
      },
      click: (row, callback) => {
        // console.log("wxw", row.cpyc);
        if (parseInt(row.cjsl) > 0)
          callback({ type: "detail_type", row: row, kind: 6 });
      },
    },
    {
      name: t("report.dcdld"),
      filed: "dcdldsl",
      render: (row) => {
        if (row.dcdldsl > 0) {
          return (
            "<span style='color:#f00;cursor: pointer;'>" +
            row.dcdldsl +
            "</span>"
          );
        } else {
          return row.dcdldsl;
        }
      },
      click: (row, callback) => {
        // console.log("wxw", row.cpyc);
        if (parseInt(row.dcdldsl) > 0)
          callback({ type: "detail_type", row: row, kind: 7 });
      },
    },
    {
      name: t("report.cgqbj"),
      filed: "cgqbjsl",
      render: (row) => {
        if (row.cgqbjsl > 0) {
          return (
            "<span style='color:#f00;cursor: pointer;'>" +
            row.cgqbjsl +
            "</span>"
          );
        } else {
          return row.cgqbjsl;
        }
      },
      click: (row, callback) => {
        // console.log("wxw", row.cpyc);
        if (parseInt(row.cgqbjsl) > 0)
          callback({ type: "detail_type", row: row, kind: 8 });
      },
    },
    {
      name: t("report.spzd"),
      filed: "spzdsl",
      render: (row) => {
        if (row.spzdsl > 0) {
          return (
            "<span style='color:#f00;cursor: pointer;'>" +
            row.spzdsl +
            "</span>"
          );
        } else {
          return row.spzdsl;
        }
      },
      click: (row, callback) => {
        // console.log("wxw", row.cpyc);
        if (parseInt(row.spzdsl) > 0)
          callback({ type: "detail_type", row: row, kind: 9 });
      },
    },
    {
      name: t("report.jjs"),
      filed: "jjs",
      render: (row) => {
        if (row.jjs > 0) {
          return (
            "<span style='color:#f00;cursor: pointer;'>" + row.jjs + "</span>"
          );
        } else {
          return row.jjs;
        }
      },
      click: (row, callback) => {
        // console.log("wxw", row.cpyc);
        if (parseInt(row.jjs) > 0)
          callback({ type: "detail_type", row: row, kind: 10 });
      },
    },
    {
      name: t("report.jjsbj"),
      filed: "jjsbj",
      render: (row) => {
        if (row.jjsbj > 0) {
          return (
            "<span style='color:#f00;cursor: pointer;'>" + row.jjsbj + "</span>"
          );
        } else {
          return row.jjsbj;
        }
      },
      click: (row, callback) => {
        // console.log("wxw", row.cpyc);
        if (parseInt(row.jjsbj) > 0)
          callback({ type: "detail_type", row: row, kind: 11 });
      },
    },
    {
      name: t("report.jzw"),
      filed: "jzw",
      render: (row) => {
        if (row.jzw > 0) {
          return (
            "<span style='color:#f00;cursor: pointer;'>" + row.jzw + "</span>"
          );
        } else {
          return row.jzw;
        }
      },
      click: (row, callback) => {
        // console.log("wxw", row.cpyc);
        if (parseInt(row.jzw) > 0)
          callback({ type: "detail_type", row: row, kind: 12 });
      },
    },
    {
      name: t("report.ADAS"),
      filed: "adas",
      render: (row) => {
        if (row.adas > 0) {
          return (
            "<span style='color:#f00;cursor: pointer;'>" + row.adas + "</span>"
          );
        } else {
          return row.adas;
        }
      },
      click: (row, callback) => {
        // console.log("wxw", row.cpyc);
        if (parseInt(row.adas) > 0)
          callback({ type: "detail_type", row: row, kind: 13 });
      },
    },
    {
      name: t("report.DMS"),
      filed: "dms",
      render: (row) => {
        if (row.dms > 0) {
          return (
            "<span style='color:#f00;cursor: pointer;'>" + row.dms + "</span>"
          );
        } else {
          return row.dms;
        }
      },
      click: (row, callback) => {
        // console.log("wxw", row.cpyc);
        if (parseInt(row.dms) > 0)
          callback({ type: "detail_type", row: row, kind: 14 });
      },
    },
    {
      name: t("report.BSD"),
      filed: "bsd",
      render: (row) => {
        if (row.bsd > 0) {
          return (
            "<span style='color:#f00;cursor: pointer;'>" + row.bsd + "</span>"
          );
        } else {
          return row.bsd;
        }
      },
      click: (row, callback) => {
        // console.log("wxw", row.cpyc);
        if (parseInt(row.bsd) > 0)
          callback({ type: "detail_type", row: row, kind: 15 });
      },
    },
    // {
    //   name: t("report.jsxwbj"),
    //   filed: "jsxwbj",
    //   render: (row) => {
    //     if (row.jsxwbj > 0) {
    //       return (
    //         "<span style='color:#f00;cursor: pointer;'>" +
    //         row.jsxwbj +
    //         "</span>"
    //       );
    //     } else {
    //       return row.jsxwbj;
    //     }
    //   },
    //   click: (row, callback) => {
    //     // console.log("wxw", row.cpyc);
    //     if (parseInt(row.jsxwbj) > 0)
    //       callback({ type: "detail_type", row: row, kind: 16 });
    //   },
    // },

    {
      name: t("report.jrdzwl"),
      filed: "jrdzwl",
      render: (row) => {
        if (row.jrdzwl > 0) {
          return (
            "<span style='color:#f00;cursor: pointer;'>" +
            row.jrdzwl +
            "</span>"
          );
        } else {
          return row.jrdzwl;
        }
      },
      click: (row, callback) => {
        // console.log("wxw", row.cpyc);
        if (parseInt(row.jrdzwl) > 0)
          callback({ type: "detail_type", row: row, kind: 17 });
      },
    },
    {
      name: t("report.lkdzwl"),
      filed: "lkdzwl",
      render: (row) => {
        if (row.lkdzwl > 0) {
          return (
            "<span style='color:#f00;cursor: pointer;'>" +
            row.lkdzwl +
            "</span>"
          );
        } else {
          return row.lkdzwl;
        }
      },
      click: (row, callback) => {
        // console.log("wxw", row.cpyc);
        if (parseInt(row.lkdzwl) > 0)
          callback({ type: "detail_type", row: row, kind: 18 });
      },
    },
    {
      name: t("report.xsqycs"),
      filed: "xsqycs",
      width: "120",
      render: (row) => {
        if (row.xsqycs > 0) {
          return (
            "<span style='color:#f00;cursor: pointer;'>" +
            row.xsqycs +
            "</span>"
          );
        } else {
          return row.xsqycs;
        }
      },
      click: (row, callback) => {
        // console.log("wxw", row.cpyc);
        if (parseInt(row.xsqycs) > 0)
          callback({ type: "detail_type", row: row, kind: 19 });
      },
    },
    {
      name: t("report.xsqyds"),
      filed: "xsqyds",
      width: "120",
      render: (row) => {
        if (row.xsqyds > 0) {
          return (
            "<span style='color:#f00;cursor: pointer;'>" +
            row.xsqyds +
            "</span>"
          );
        } else {
          return row.xsqyds;
        }
      },
      click: (row, callback) => {
        // console.log("wxw", row.cpyc);
        if (parseInt(row.xsqyds) > 0)
          callback({ type: "detail_type", row: row, kind: 20 });
      },
    },
  ],
  tableOps: [
    {
      opType: "btn",
      icon: markRaw(Search),
      name: t("commKey.DETAIL"),
      type: "success",
      click: (row, callback) => {
        callback({ type: "detail", row: row });
      },
    },
  ],
  searchCols: [
    // {
    //   type: "tsel",
    //   ref: "refTree",
    //   key: "devids",
    //   val: [],
    //   title: t("commKey.keyname"),
    //   props: {
    //     icon: "icon",
    //     value: "gps_id",
    //     label: "nodeName",
    //     children: "children",
    //   },
    // },
    {
      type: "car",
      key: "devids",
    },
    {
      type: "sel",
      key: "dateType",
      val: 1,
      title: "",
      data: [
        { name: t("map.jintian"), value: 1 },
        { name: t("map.zuotian"), value: 2 },
        { name: t("map.benzhou"), value: 3 },
        { name: t("map.shangzhou"), value: 4 },
        { name: t("map.benyue"), value: 5 },
        { name: t("map.shangyue"), value: 6 },
        { name: t("map.zidingyi"), value: 7 },
      ],
      change: (val, callback) => {
        callback({ type: "change_time", evt: val });
        // console.log(obj);
      },
    },
    {
      type: "datetime",
      key: "begintime",
      val: moment().format("YYYY-MM-DD 00:00:00"),
      title: t("commKey.STARTDATE"),
    },
    {
      type: "datetime",
      key: "endtime",
      val: moment().format("YYYY-MM-DD 23:59:59"),
      title: t("commKey.ENDDATE"),
    },
    // {
    //   type: "dtr",
    //   key: "times",
    //   val: [
    //     moment().format("YYYY-MM-DD 00:00:00"),
    //     moment().format("YYYY-MM-DD 23:59:59"),
    //   ],
    //   title_s: t("commKey.STARTDATE"),
    //   title_e: t("commKey.ENDDATE"),
    // },

    {
      type: "btn",
      btnType: "success",
      icon: markRaw(Search),
      title: t("commKey.Search"),
      click: (callback) => {
        callback({ type: "search" });
      },
    },

    {
      type: "btn",
      btnType: "",
      icon: markRaw(Download),
      title: t("commKey.EXCEL"),
      click: (callback) => {
        callback({ type: "download" });
      },
    },
    {
      type: "btn",
      btnType: "warning",
      icon: markRaw(Download),
      title: t("commKey.PDF"),
      click: (callback) => {
        callback({ type: "export" });
      },
    },
  ],
  tableDetailCols: [
    { name: t("report.cph"), filed: "cph" },
    { name: t("report.sbh"), filed: "sbh" },
    { name: t("report.ssgs"), filed: "ssgs" },
    { name: t("report.bjsj"), filed: "gpssj" },
    { name: t("report.ejsj"), filed: "aAlarmEndTime" },
    {
      name: t("report.bjnr"),
      filed: "bgzt",
      render: (row) => {
        return t("report.e_" + row.bgzt);
      },
    },
    {
      name: t("report.ckfj"),
      filed: "ckfj",
      render: (row) => {
        return "<span class='iconfont icon-yanjing_xianshi da3'></span>";
      },
      click: (row, callback) => {
        console.log("sss", row);
        callback({
          type: "view_files",
          row: row,
        });
      },
    },
    // {
    //   name: t("report.ckfj"),
    //   filed: "ckfj",
    //   render: (row) => {
    //     return "查看";
    //   },
    //   click: (row, callback) => {
    //     console.log("sss", row);
    //     callback({
    //       type: "view_files",
    //       row: row,
    //     });
    //   },
    // },
    {
      name: t("report.xq"),
      filed: "xq",
      render: (row) => {
        let rt;
        if (row["bgzt"] == 102) {
          var str_str = row.xq;
          var str_ay = str_str.split(",");
          var result_ay = [];
          for (var i = 0; i < str_ay.length; i++) {
            if (str_ay[i] != "") {
              result_ay.push("CH" + str_ay[i]);
            }
          }
          rt = result_ay.join(",");
        }
        return rt;
      },
    },
    { name: t("report.su"), filed: "sudu" },
    {
      name: t("report.gps_dw"),
      filed: "dw",      
      render: (row) => {
        return t("map.gps_" + row.islatlng);
      },
    },
    {
      name: t("report.dswz"),
      filed: "wz",
      render: (row) => {
        if (row.addr == "") {
          return (
            "<img src=" +
            require("../../assets/map.png") +
            " style='width:25px'>"
          );
        } else {
          return row.addr;
        }
      },
      click: (row, callback) => {
        if (row.pLongitude && row.pLatitude)
          callback({
            type: "get_addr",
            row: row,
            fd: "addr",
            lng: row.pLongitude,
            lat: row.pLatitude,
          });
        // console.log(row);
      },
      // render: (row) => {
      //   return row.address_info;
      // },
    },
  ],
};
